@import 'bourbon/bourbon'; // http://bourbon.io/

@import 'variables'; // colors, fonts etc...

@import 'mixins'; // custom mixins

@import 'layout'; // responsive grid and media queries

/* -------------------------------- 

Primary style

-------------------------------- */

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*, *:after, *:before {
    box-sizing: border-box;
}

html, body {
    /* you need to set this to assign to the main element a min height of 100% */
    height: 100%;
}

body {
    font: {
        size: 100%;
        family: $primary-font; // variables inside partials > _variables.scss
    }
    color: $main-text;
    background-color: $background;
}

.responsive-nav {
    font: {
        family: $nav-font;
        size: 15px;
    }
}

a {
    color: $link;
    text-decoration: none;
}

/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */

.cd-img-replace { /* replace text with a background-image */
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

.overflow-hidden {
    overflow: hidden;
}

/* -------------------------------- 

Main components 

-------------------------------- */

.frontpage-img {
    max-width: 100%;
    height: 400px;
    margin: 0 auto;
    object-fit: cover;
    display: block;
}

.cd-main-content {
    /* set a min-height and a z-index to be sure that the main element completely covers the lateral menu */
    min-height: 100%;
    position: relative;
    background-color: $background;
    z-index: $content-zindex;
    
    //we assign a top padding since the header is in position absolute or fixed
    padding-top: $header-S;

    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    @include transition-property(transform);
    @include transition-duration(.4s);

    &.lateral-menu-is-open {
        /* translate to show the lateral menu - all content needs to be put in the .cd-main-content to translate*/
        @include transform(translateX(-260px));
    }

    @include MQ(M) {
        padding-top: $header-M;
    }
}

header.responsive-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: $header-S;
    width: 100%;
    background: $color-1;
    z-index: $header-zindex;

    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    @include transition-property(transform);
    @include transition-duration(.4s);

    &.lateral-menu-is-open {
        /* translate to show the lateral menu */
        @include transform(translateX(-260px));
    }
    
    //assign this class to the header if you want a fixed navigation
    &.is-fixed {
        position: fixed;
    }

    @include MQ(M) {
        height: $header-M;
    }
}

#cd-logo {
    display: block;
    float: left;
    height: 100%;
    color: $title-color;

    #logo-title {
        float: left;
        font-weight: bolder;
        font-size: x-large;
        margin-left: $header-S / 4;
        margin-top: $header-S / 4;
        @include MQ(M) {
            margin-top: $header-M / 3;
            margin-left: $header-M / 4;
        }
    }

    /*@include MQ(M) {
        margin: 22px 0 0 30px;
    }*/
}

#cd-top-nav {
    position: absolute;
    top: 0;
    right: 120px;
    height: 100%;

    display: none;

    ul {
        height: 100%;
        padding-top: 18px;
    }

    li {
        display: inline-block;
        margin-right: 1em;
    }

    a {
        display: inline-block;
        padding: .5em;
        color: #FFF;
        text-transform: uppercase;
        font-weight: 600;

        &.current {
            background-color: $color-2;
        }

        .no-touch &:hover {
            color: rgba(#FFF, .7);
        }
    }

    @include MQ(M) {
        display: block;
    }
}

#cd-menu-trigger {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: lighten($color-1, 10%);

    .cd-menu-text {
        height: 100%;

        text-transform: uppercase;
        color: #FFF;
        font-weight: 600;

        display: none;
    }

    .cd-menu-icon {
        /* this span is the central line in the menu menu */
        display: inline-block;
        position: absolute;
        @include center; // mixin inside partials > _mixings.scss
        width: 18px;
        height: 2px;
        background-color: #FFF;
        
        /* these are the upper and lower lines in the menu menu */
        &::before, &:after {
            content: '';
            width: 100%;
            height: 100%; 
            position: absolute;
            background-color: inherit;
            left: 0;
        }
        
        &::before {
            bottom: 5px;
        }

        &::after {
            top: 5px;
        }
    }

    &.is-clicked {
        .cd-menu-icon {
            background-color: rgba(#FFF, 0);

            &::before, &::after {
                background-color: rgba(#FFF, 1);
            }

            &::before {
                bottom: 0;
                @include transform(rotate(45deg));
            }

            &::after {
                top: 0;
                @include transform(rotate(-45deg));
            }
        }
    }

    @include MQ(M) {
        width: 60px;
        padding-left: 1.25em;

        .cd-menu-text {
            display: inline-block;
            line-height: $header-M;
        }

        .cd-menu-icon {
            left: auto;
            right: 1.25em;
            @include transform(translateX(0));
        }
    }
}

#cd-lateral-nav {
    position: fixed;
    height: 100%;
    right: 0;
    top: 0;
    visibility: hidden;

    /* the secondary navigation is covered by the main element */
    z-index: $secondary-nav; // see partials > _variables.scss

    width: 260px;
    background-color: $color-2;

    overflow-y: auto;

    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    -webkit-transition: -webkit-transform .4s 0s, visibility 0s .4s;
    -moz-transition: -moz-transform .4s 0s, visibility 0s .4s;
    transition: transform .4s 0s, visibility 0s .4s;

    /* this creates the subtle slide in animation of the navigation */
    @include transform(translateX(80px));

    .cd-navigation {
        margin: 10px 0 16px;

        .fa {
            margin-right: 10px;
            font-size: 23px;
        }
    }

    .sub-menu {
        padding: 0 10px 28px 15px;
        display: none;
    }

    a {
        display: block;
        line-height: 2em;
        padding: 0 16px 0 32px;
        color: $color-3;

        &.current {
            background-color: lighten($color-2, 10%);
            color: #FFF;
        }

        .no-touch &:hover {
            color: #FFF;
        }
    } 

    @include MQ(M) {
        .cd-navigation {
            margin: 20px 0;
        }
    }

    &.lateral-menu-is-open {
        @include transform(translateX(0));
        visibility: visible;
        -webkit-transition: -webkit-transform .4s 0s, visibility 0s 0s;
        -moz-transition: -moz-transform .4s 0s, visibility 0s 0s;
        transition: transform .4s 0s, visibility 0s 0s;

        /* smooth the scrolling on touch devices - webkit browsers */
        -webkit-overflow-scrolling: touch;
    }
}


/* style menu items which have a submenu  */
#cd-lateral-nav .item-has-children > a {
    position: relative;
    text-transform: uppercase;
    font-weight: 600;

    /* this is the right arrow to show that the item has a submenu  */
    &::after {
        content: '';
        display: block;
        height: 11px;
        width: 8px;
        position: absolute;
        @include center(y);
        right: 1em;
        background: url('/images/cd-arrow.svg') no-repeat center center;
        background-size: 8px 11px;

        @include transition-property(transform);
        @include transition-duration(.2s);
    }

    &.submenu-open::after {
        @include transform(translateY(-50%) rotate(90deg));

    }
}

#cd-lateral-nav .socials {
    @include clearfix;
    padding: 0 32px;

    a {
        height: 32px;
        width: 32px;
        float: left;
        padding: 0;
        background-image: url('/images/cd-socials.svg');
        background-repeat: no-repeat;
        background-size: 128px 64px;
        background-color: #FFF;
        margin-right: .5em;
        @include border-radius;

        &.cd-twitter {
            background-position: 0 0;
        }

        &.cd-github {
            background-position: -32px 0;
        }

        &.cd-facebook {
            background-position: -64px 0;
        }

        &.cd-google {
            background-position: -96px 0;
        }

        .no-touch &:hover {
            background-color: $color-1;

            &.cd-twitter {
                background-position: 0 -32px;
            }

            &.cd-github {
                background-position: -32px -32px;
            }

            &.cd-facebook {
                background-position: -64px -32px;
            }

            &.cd-google {
                background-position: -96px -32px;
            }
        }
    }
}

.responsive-float {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    a,img {
        padding: 5px;
        background-image: none !important;
    }
}

.model-list {
}

div.model-item {
    max-width: 300px;
    /*max-height: 300px;*/
    padding: 20px;
    margin: 10px 0;

    .model-item-title {
        text-align: center;
        padding: 10px;
    }

    .model-image {
        max-width: 250px;
        height: 250px;
        object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

video {
    width: 100%;
}
