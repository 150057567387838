// colors

$main-text: #4e6361; // main text
$link: blue; // anchor tags
$background: #d8d8d8; // body background color

$color-1: #606060; // green dark
$color-2: #333333; // green darker
$color-3: #d8d8d8; // green light
$title-color: #E7DFC6;

// fonts 

$nav-font: 'Arial', 'Titillium Web', sans-serif;
$primary-font: 'Arial', 'Merriweather', 'PT Serif', Georgia, 'Times New Roman', serif !default;

// z-index 

$secondary-nav: 1;
$content-zindex: 2;
$header-zindex: 3;

// header height 

$header-S: 50px;
$header-M: 70px;
